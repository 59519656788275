import React, { useEffect, useState } from "react";
import MonthDisplay from "./Months";
import numberToWords from "./numbersToWords";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { getToken } from "../services/Token";
import { useCreateProfileQuery } from "../api/profileApi";
import {
  useLazyGetAllDesignationsIdQuery,
  useLazyGetAllDapartmentQuery,
  useLazyGetWorkLocationsQuery,
} from "../api/addEmployeeApi";
import { usePayslipDetailsMutation } from "../api/loginApi";
import noResultsFound from "../Icons/NoresultsFound.svg";
import { get_file_from_s3 } from "../utils";
import { toast } from "react-toastify";


const PaySlip = () => {
  const empId = getToken("Id");
  const { data: employeeData, isSuccess: employeeSuccess } =
    useCreateProfileQuery({
      employeeId: `${empId}`,
    });
  const [getAllDapartment, { data: departmentData }] =
    useLazyGetAllDapartmentQuery({});
  const [getAllDesignationsId, { data: designationData }] =
    useLazyGetAllDesignationsIdQuery({});
  const [getWorkLocations, { data: locationData }] =
    useLazyGetWorkLocationsQuery({});
  const [department, setDepartment] = useState();
  const [designation, setDesignation] = useState();
  const [location, setLocation] = useState();
  const [buttonClick, setButtonClick] = useState(false);
  const paySlipDate = localStorage.getItem("paySlipDate");
  const [formattedDate, setFormattedDate] = useState('');
  const [payslipDetails, { data: paySlipDetails, error }] =
    usePayslipDetailsMutation();
  const totalNetPayWords = numberToWords(paySlipDetails?.data?.totalNetPay);
  const handleClickHide = () => {
    setButtonClick(true);
  };
  const handleClickInfo = () => {
    setButtonClick(false);
  };
  const handleDownload = async (file) => {
    try {
      const res = await get_file_from_s3(file?.fileName);
      const s3Url = res;

      if (!s3Url) {
        toast.error("Failed to fetch the file");
        return;
      }

      const response = await fetch(s3Url);
      const blob = await response.blob();

      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);

      const urlParts = s3Url.split("/");
      const fullFileName = urlParts[urlParts.length - 1]; //need to change as per filename
      const [filename] = fullFileName.split("?");

      downloadLink.setAttribute("download", filename);
      document.body.appendChild(downloadLink);
      downloadLink.click();

      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  useEffect(() => {
    const currentDate = dayjs();
    const formatted = currentDate.toISOString();
    setFormattedDate(formatted);
  }, []);

  useEffect(() => {
    if (paySlipDate || formattedDate) {
      let dateTime = formattedDate; // Default to formattedDate
  
      if (paySlipDate) {
        const paySlipDates = new Date(paySlipDate);
        if (!isNaN(paySlipDates.getTime())) { // Check if paySlipDates is a valid date
          dateTime = paySlipDates.toISOString();
        } else {
          console.error("Invalid paySlipDate value:", paySlipDate);
        }
      }
                  
      payslipDetails({
        employeeId: empId,
        dateTime: dateTime,
      });
    }
  }, [paySlipDate, formattedDate, empId]);
  

  useEffect(() => {
    if (employeeSuccess) {
      getAllDapartment();
      getAllDesignationsId();
      getWorkLocations();
      departmentData?.forEach((item) => {
        if (item._id === employeeData?.professionalDetails?.department) {
          setDepartment(item?.departmentName);
        }
      });

      locationData?.forEach((item) => {
        if (item._id === employeeData?.professionalDetails?.workLocation) {
          setLocation(item?.location);
        }
      });
      designationData?.forEach((item) => {
        if (
          item._id === employeeData?.professionalDetails?.currentDesignation
        ) {
          setDesignation(item?.designation);
        }
      });
    }
  }, [employeeSuccess, departmentData, designationData, locationData]);

  const handleDateChange = (date) => {
    if (date) {
      localStorage.setItem("paySlipDate", date);
      const dateObj = new Date(date);
      if (!isNaN(dateObj.getTime())) {
        const isoString = dateObj.toISOString();
        payslipDetails({
          employeeId: empId,
          dateTime: isoString,
        });
      }
    }
  };
  
  const maxDate = dayjs();

  return (
    <div>
          <div className="flex gap-8 mb-4 justify-end">
          {Object.keys(paySlipDetails?.data || {}).length !== 0 &&
            <svg
              title="Download Letter Template"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 cursor-pointer"
              onClick={() =>
                handleDownload(paySlipDetails?.data?.paySlipDetails?.s3Document)
              }
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
              />
            </svg>}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                 maxDate={maxDate}
                value={paySlipDate ? dayjs(paySlipDate) : dayjs(formattedDate)}
                openTo="month"
                views={["month", "year"]}
                onChange={(date) => {
                  handleDateChange(date);
                }}
                sx={{
                  maxHeight: 30,
                  width: "15rem",
                  bgcolor: "white",
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginLeft: "1.25rem",
                  "& .MuiInputBase-root": {
                    height: 30,
                  },
                  borderRadius: "0.375rem",
                }}
              />
            </LocalizationProvider>
          </div>
           {Object.keys(paySlipDetails?.data || {}).length !== 0 ?  (
          <div className="flex flex-col gap-2">
            <div className="flex flex-row gap-4 items-stretch h-auto">
              <div className="flex flex-col w-2/4 border border-gray-500 rounded-md bg-white text-gray-600 font-medium justify-between">
                <div className="flex flex-col ">
                  <div className="px-4 py-4">Earings</div>
                  <h1 className="flex justify-end bg-blue-200 border border-y-gray-500 px-4 py-4">
                    Amount in (₹)
                  </h1>
                  <div className="flex flex-col gap-4">
                    {paySlipDetails?.data?.totalGrossPayDetails.map((item, i) => (
                      <div
                        className="flex flex-row justify-between px-4 py-4 hover:bg-blue-200"
                        key={i + 1}
                      >
                        <h1>{item?.displayText ? item?.displayText : "--"}</h1>
                        <h1>{item?.value ? item?.value : "--"}</h1>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <div>
                    <div className="flex flex-row justify-between bg-gray-500 px-4 py-4 text-black font-medium">
                      <h1>TOTAL</h1>
                      <h1>
                        {paySlipDetails?.data?.totalGrossPay
                          ? paySlipDetails?.data?.totalGrossPay
                          : "--"}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-2/4 border border-gray-500 rounded-md bg-white text-gray-600 font-medium justify-between">
                <div className="flex flex-col ">
                  <div className="px-4 py-4">Deductions</div>
                  <h1 className="flex justify-end bg-blue-200 border  border-y-gray-500 px-4 py-4">
                    Amount in (₹)
                  </h1>

                  <div className="flex flex-col gap-4 ">
                    {paySlipDetails?.data?.totalDeductionDetails.map((item, i) => (
                      <div
                        className="flex flex-row justify-between px-4 py-4 hover:bg-blue-200"
                        key={i + 1}
                      >
                        <h1>{item?.displayText ? item?.displayText : "--"}</h1>
                        <h1>{item?.value ? item?.value : "--"}</h1>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <div className="flex flex-row justify-between bg-gray-500 px-4 py-4 text-black font-medium">
                    <h1>TOTAL</h1>
                    <h1>
                      {paySlipDetails?.data?.totalDeduction
                        ? paySlipDetails?.data?.totalDeduction
                        : "--"}
                    </h1>
                  </div>
                </div>
              </div>
              {buttonClick === true ? (
                <div className="bg-white w-3/4 px-4 py-4 border border-gray-300 rounded-md flex flex-col gap-1 relative">
                  <div className="flex flex-col">
                    <div className="flex flex-row justify-between">
                      <h1 className="text-purple-800  font-bold">
                        {" "}
                        Employee Details
                      </h1>
                      <button
                        className="text-blue-700  font-semibold cursor-pointer hover:underline"
                        onClick={handleClickInfo}
                      >
                        info
                      </button>
                    </div>
                    <div className="flex justify-center items-center  max-h-screen absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      <div className=" self-end px-4 py-4 flex flex-col gap-2 mr-[0rem] ml-[12rem] rounded-lg">
                        <h1 className="text-black font-medium flex items-center w-[22rem]">
                          Net Pay for<span className="ml-2"><MonthDisplay
                              monthNumber={
                                paySlipDetails?.data?.payrollMonth
                                  ? paySlipDetails?.data?.payrollMonth
                                  : "--"
                              }
                            />
                          </span>
                          {paySlipDetails?.data?.payrollYear
                            ? paySlipDetails?.data?.payrollYear
                            : "--"}
                        </h1>
                        <h1 className="text-green-700 font-bold">
                          ₹
                          {paySlipDetails?.data?.totalNetPay
                            ? paySlipDetails?.data?.totalNetPay
                            : "--"}
                        </h1>
                        <h1 className="text-gray-500 font-medium">
                          {totalNetPayWords ?? "--"}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="bg-white w-3/4 px-4 py-4 border border-gray-300 rounded-md flex flex-col gap-1">
                  <div className="flex flex-row justify-between">
                    <h1 className="text-purple-800 font-bold">
                      {" "}
                      Employee Details
                    </h1>
                    <button
                      className="text-blue-700  font-semibold cursor-pointer hover:underline"
                      onClick={handleClickHide}
                    >
                      Hide
                    </button>
                  </div>
                  <div className="flex flex-row justify-between mt-2">
                    <h1 className="text-blue-500  font-semibold"> Name</h1>
                    <h1 className="text-blue-500  font-semibold">
                      Employee No
                    </h1>
                  </div>
                  <div className="flex flex-row justify-between mt-2">
                    <h1 className="text-black  font-semibold">
                      {" "}
                      {employeeData?.firstName ? employeeData?.firstName : "--"}
                    </h1>
                    <h1 className="text-black  font-semibold">
                      {employeeData?.professionalDetails?.employeeId
                        ? employeeData?.professionalDetails?.employeeId
                        : "--"}
                    </h1>
                  </div>
                  <div className="flex flex-row justify-between mt-2">
                    <h1 className="text-blue-500  font-semibold">
                      {" "}
                      Joining Date
                    </h1>
                    <h1 className="text-blue-500  font-semibold">Bank Name</h1>
                  </div>
                  <div className="flex flex-row justify-between mt-2">
                    <h1 className="text-black font-semibold">
                      {" "}
                      {employeeData?.professionalDetails?.dateofJoining
                        ? dayjs(
                            employeeData?.professionalDetails?.dateofJoining
                          ).format("DD/MM/YYYY")
                        : "-"}{" "}
                    </h1>
                    <h1 className="text-black  font-semibold">
                      {employeeData?.bankDetails?.bankName
                        ? employeeData?.bankDetails?.bankName
                        : "--"}
                    </h1>
                  </div>
                  <div className="flex flex-row justify-between mt-2">
                    <h1 className="text-blue-500  font-semibold">
                      Designation
                    </h1>
                    <h1 className="text-blue-500  font-semibold">
                      Bank Account No
                    </h1>
                  </div>
                  <div className="flex flex-row justify-between mt-2">
                    <h1 className="text-black  font-semibold">
                      {designation ?? "--"}
                    </h1>
                    <h1 className="text-black  font-semibold">
                      {employeeData?.bankDetails?.accountNo
                        ? employeeData?.bankDetails?.accountNo
                        : "--"}
                    </h1>
                  </div>
                  <div className="flex flex-row justify-between mt-2">
                    <h1 className="text-blue-500  font-semibold">Department</h1>
                    <h1 className="text-blue-500  font-semibold">PAN Number</h1>
                  </div>
                  <div className="flex flex-row justify-between mt-2">
                    <h1 className="text-black  font-semibold">
                      {department ?? "--"}
                    </h1>
                    <h1 className="text-black  font-semibold">
                      {employeeData?.personalDetails?.panNumber
                        ? employeeData?.personalDetails?.panNumber
                        : "--"}
                    </h1>
                  </div>
                  <div className="flex flex-row justify-between mt-2">
                    <h1 className="text-blue-500  font-semibold">Location</h1>
                  </div>
                  <div className="flex flex-row justify-between mt-2">
                    <h1 className="text-black  font-semibold">
                      {location ?? "--"}
                    </h1>
                  </div>
                </div>
              )}
            </div>
            {buttonClick === true ? (
              ""
            ) : (
              <div className="bg-white w-1/4 self-end px-4 py-4 flex flex-col gap-2 mx-4 rounded-lg">
                <h1 className="text-black font-medium flex items-center">
                  Net Pay for{" "}
                  <span className="ml-2 mr-2">
                    <MonthDisplay monthNumber={paySlipDetails?.data?.payrollMonth} />
                  </span>
                  {paySlipDetails?.data?.payrollYear
                    ? paySlipDetails?.data?.payrollYear
                    : "--"}
                </h1>
                <h1 className="text-green-700 font-bold">
                  ₹{" "}
                  {paySlipDetails?.data?.totalNetPay
                    ? paySlipDetails?.data?.totalNetPay
                    : "--"}
                </h1>
                <h1 className="text-gray-500 font-medium">
                  {totalNetPayWords ?? "--"}
                </h1>
              </div>
            )}
          </div>
      ) : (
        <div className="flex flex-col items-center justify-center">
          <div className="text-center">
            <img
              src={noResultsFound}
              alt="No Results Found"
              className="mx-auto mb-4 w-48 h-48"
            />
            <h1 className="text-2xl font-bold text-gray-800 mb-2">
            {paySlipDetails?.message?paySlipDetails?.message:'No results found'}
            </h1>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaySlip;
