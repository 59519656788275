import React, { useState } from "react";
import { genericHeaders, serviceUrl } from "../../../utils/apiConfig";
import axios from "axios";
import { get_file_from_s3, upload_file_to_s3 } from "../../../utils";
import PdfPreview from "../pdfPreview";

const UploadTemplate = ({ setFilePlaceholders, setFormData, formData }) => {
  const [url, setUrl] = useState("");

  const onSelectFile = (e) => {
    setUrl("");
    const selectedFile = e.target.files[0];
    console.log(selectedFile,"SELECTEDFILE");
    const formData = new FormData();
    formData.append("file", selectedFile);
    uploadFiletoS3Bucket(selectedFile);
    extractDataFromFile(formData);
  };

  const uploadFiletoS3Bucket = async (new_file) => {
    try {
      const maxSizeInBytes = 10 * 1024 * 1024; // max 10mb

      if (new_file?.size > maxSizeInBytes) {
        return
      }

      const UId = localStorage.getItem("Id");
      const s3path = await upload_file_to_s3(new_file);
      console.log(s3path,"DATA")
      const public_url = s3path.data.data;
      console.log(public_url.data,"PUBLIC_URL")
      let [name, extension] = new_file.name.split(".");
      console.log(name,"NAME")
      if(public_url){
        const s3Url =  await get_file_from_s3(public_url.fileName);
       setUrl(s3Url);
        console.log(s3Url,"NAMEURL")
      }

      setFormData({
        ...formData,
        document: {
          fileName: name,
          extension: extension,
          resourceName: "",
          s3Document:public_url 
        },
      });
    } catch (e) {
      console.log(e, "erororr345");
    }
  };

  const extractDataFromFile = async (formData) => {
    try {
      const url = `${serviceUrl}/api/letterManagement/extrackTextFromFile`;
      let headers = { ...genericHeaders() };
      headers["Content-Type"] =
        "*/*";
      const response = await axios.post(url, formData, {
        headers: headers,
      });
      if (response.status === 200) {
        setFilePlaceholders(response.data);
      }
    } catch (err) {
      console.error("An error occurred during file upload:", err);
    }
  };

  return (
    <div className="flex justify-start">
      <div className="">
        <h3 className="text-xl my-2 p-2 font-semibold">
          Step 2: Upload Template
        </h3>

        <div className="flex mt-5 items-center">
          <label className="mr-2">Template:</label>
          <div className="flex items-center">
            <div class="bg-grey-lighter mr-2">
              <label class="w-full flex flex-col text-white items-center px-4 py-1 bg-[#3f51b5] text-blue rounded-lg shadow-lg tracking-wide cursor-pointer hover:bg-blue hover:text-white">
                <span class="text-base leading-normal">Select a file</span>
                <input
                  onChange={onSelectFile}
                  accept=".doc,.docx,application/pdf"
                  type="file"
                  class="hidden"
                />
              </label>
            </div>

            {/* <input
              type="file"
              onChange={onSelectFile}
              accept=".doc,.docx,application/pdf"
            /> */}
            <p>Note: Only PDF and DOCX files are accepted</p>
          </div>
        </div>
        {formData.document.fileName && formData.document.extension && (
          <p className="mt-2">
            selected File Name: {formData.document.fileName}.
            {formData.document.extension}
          </p>
        )}
        <div className="mt-12 flex">
          <label>Preview: </label>
          <div className="flex justify-center w-full min-h-[350px] ml-2 border border-black">
            {formData.document.extension &&
            formData.document.extension.toLowerCase() !== "pdf" ? (
              <p className="flex p-2">
                Preview is not available for docx files, please try uploading
                pdf files for preview.
              </p>
            ) : (
              <PdfPreview url={url} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadTemplate;
